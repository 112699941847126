/*                                 Apache License
                           Version 2.0, January 2004
                        http://www.apache.org/licenses/
Software in the Sustain Ecosystem are Released Under Terms of Apache Software License 
This research has been supported by funding from the US National Science Foundation's CSSI program through awards 1931363, 1931324, 1931335, and 1931283. The project is a joint effort involving Colorado State University, Arizona State University, the University of California-Irvine, and the University of Maryland - Baltimore County. All redistributions of the software must also include this information. 
TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION
1. Definitions.
"License" shall mean the terms and conditions for use, reproduction, and distribution as defined by Sections 1 through 9 of this document.
"Licensor" shall mean the copyright owner or entity authorized by the copyright owner that is granting the License.
"Legal Entity" shall mean the union of the acting entity and all other entities that control, are controlled by, or are under common control with that entity. For the purposes of this definition, "control" means (i) the power, direct or indirect, to cause the direction or management of such entity, whether by contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding shares, or (iii) beneficial ownership of such entity.
"You" (or "Your") shall mean an individual or Legal Entity exercising permissions granted by this License.
"Source" form shall mean the preferred form for making modifications, including but not limited to software source code, documentation source, and configuration files.
"Object" form shall mean any form resulting from mechanical transformation or translation of a Source form, including but not limited to compiled object code, generated documentation, and conversions to other media types.
"Work" shall mean the work of authorship, whether in Source or Object form, made available under the License, as indicated by a copyright notice that is included in or attached to the work (an example is provided in the Appendix below).
"Derivative Works" shall mean any work, whether in Source or Object form, that is based on (or derived from) the Work and for which the editorial revisions, annotations, elaborations, or other modifications represent, as a whole, an original work of authorship. For the purposes of this License, Derivative Works shall not include works that remain separable from, or merely link (or bind by name) to the interfaces of, the Work and Derivative Works thereof.
"Contribution" shall mean any work of authorship, including the original version of the Work and any modifications or additions to that Work or Derivative Works thereof, that is intentionally submitted to Licensor for inclusion in the Work by the copyright owner or by an individual or Legal Entity authorized to submit on behalf of the copyright owner. For the purposes of this definition, "submitted" means any form of electronic, verbal, or written communication sent to the Licensor or its representatives, including but not limited to communication on electronic mailing lists, source code control systems, and issue tracking systems that are managed by, or on behalf of, the Licensor for the purpose of discussing and improving the Work, but excluding communication that is conspicuously marked or otherwise designated in writing by the copyright owner as "Not a Contribution."
"Contributor" shall mean Licensor and any individual or Legal Entity on behalf of whom a Contribution has been received by Licensor and subsequently incorporated within the Work.
2. Grant of Copyright License. Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare Derivative Works of, publicly display, publicly perform, sublicense, and distribute the Work and such Derivative Works in Source or Object form.
3. Grant of Patent License. Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable (except as stated in this section) patent license to make, have made, use, offer to sell, sell, import, and otherwise transfer the Work, where such license applies only to those patent claims licensable by such Contributor that are necessarily infringed by their Contribution(s) alone or by combination of their Contribution(s) with the Work to which such Contribution(s) was submitted. If You institute patent litigation against any entity (including a cross-claim or counterclaim in a lawsuit) alleging that the Work or a Contribution incorporated within the Work constitutes direct or contributory patent infringement, then any patent licenses granted to You under this License for that Work shall terminate as of the date such litigation is filed.
4. Redistribution. You may reproduce and distribute copies of the Work or Derivative Works thereof in any medium, with or without modifications, and in Source or Object form, provided that You meet the following conditions:
You must give any other recipients of the Work or Derivative Works a copy of this License; and
You must cause any modified files to carry prominent notices stating that You changed the files; and
You must retain, in the Source form of any Derivative Works that You distribute, all copyright, patent, trademark, and attribution notices from the Source form of the Work, excluding those notices that do not pertain to any part of the Derivative Works; and
If the Work includes a "NOTICE" text file as part of its distribution, then any Derivative Works that You distribute must include a readable copy of the attribution notices contained within such NOTICE file, excluding those notices that do not pertain to any part of the Derivative Works, in at least one of the following places: within a NOTICE text file distributed as part of the Derivative Works; within the Source form or documentation, if provided along with the Derivative Works; or, within a display generated by the Derivative Works, if and wherever such third-party notices normally appear. The contents of the NOTICE file are for informational purposes only and do not modify the License. You may add Your own attribution notices within Derivative Works that You distribute, alongside or as an addendum to the NOTICE text from the Work, provided that such additional attribution notices cannot be construed as modifying the License. 
You may add Your own copyright statement to Your modifications and may provide additional or different license terms and conditions for use, reproduction, or distribution of Your modifications, or for any such Derivative Works as a whole, provided Your use, reproduction, and distribution of the Work otherwise complies with the conditions stated in this License.
5. Submission of Contributions. Unless You explicitly state otherwise, any Contribution intentionally submitted for inclusion in the Work by You to the Licensor shall be under the terms and conditions of this License, without any additional terms or conditions. Notwithstanding the above, nothing herein shall supersede or modify the terms of any separate license agreement you may have executed with Licensor regarding such Contributions.
6. Trademarks. This License does not grant permission to use the trade names, trademarks, service marks, or product names of the Licensor, except as required for reasonable and customary use in describing the origin of the Work and reproducing the content of the NOTICE file.
7. Disclaimer of Warranty. Unless required by applicable law or agreed to in writing, Licensor provides the Work (and each Contributor provides its Contributions) on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are solely responsible for determining the appropriateness of using or redistributing the Work and assume any risks associated with Your exercise of permissions under this License.
8. Limitation of Liability. In no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless required by applicable law (such as deliberate and grossly negligent acts) or agreed to in writing, shall any Contributor be liable to You for damages, including any direct, indirect, special, incidental, or consequential damages of any character arising as a result of this License or out of the use or inability to use the Work (including but not limited to damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses), even if such Contributor has been advised of the possibility of such damages.
9. Accepting Warranty or Additional Liability. While redistributing the Work or Derivative Works thereof, You may choose to offer, and charge a fee for, acceptance of support, warranty, indemnity, or other liability obligations and/or rights consistent with this License. However, in accepting such obligations, You may act only on Your own behalf and on Your sole responsibility, not on behalf of any other Contributor, and only if You agree to indemnify, defend, and hold each Contributor harmless for any liability incurred by, or claims asserted against, such Contributor by reason of your accepting any such warranty or additional liability. 
END OF TERMS AND CONDITIONS
*/

@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic,600");

/*
	Telephasic by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

:root {
	--darkBlueBackground: #1628c9;
	--midBlueBackground: #508beb;
	--lightBlueBackground: #21bad1;
	--hoverBlue: #1565c0;
	--hoverBlueOutline: #1565c0;
	--hoverBlueTranslucent: rgba(80,139,235,0.75);
}

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}




/* Carousel */

.carousel {
	position: relative;
	overflow: hidden;
	padding: 2em 0 2em 0;
	margin-bottom: 0;
}

	.carousel .forward, .carousel .backward {
		position: absolute;
		top: 50%;
		width: 6em;
		height: 12em;
		margin-top: -6em;
		cursor: pointer;
	}

		.carousel .forward:before, .carousel .backward:before {
			content: '';
			display: block;
			width: 6em;
			height: 6em;
			border-radius: 100%;
			background-color: rgba(72, 57, 73, 0.5);
			position: absolute;
			top: 50%;
			margin-top: -3em;
			-moz-transition: background-color 0.35s ease-in-out;
			-webkit-transition: background-color 0.35s ease-in-out;
			-o-transition: background-color 0.35s ease-in-out;
			-ms-transition: background-color 0.35s ease-in-out;
			transition: background-color 0.35s ease-in-out;
			-webkit-backface-visibility: hidden;
		}

		.carousel .forward:after, .carousel .backward:after {
			content: '';
			width: 3em;
			height: 3em;
			position: absolute;
			top: 50%;
			margin: -1.5em 0 0 0;
			background: url("images/arrow.svg") no-repeat center center;
		}

		.carousel .forward:hover:before, .carousel .backward:hover:before {
			background-color: var(--hoverBlueTranslucent);
		}

	.carousel .forward {
		right: 0;
	}

		.carousel .forward:before {
			right: -3em;
		}

		.carousel .forward:after {
			right: -0.25em;
		}

	.carousel .backward {
		left: 0;
	}

		.carousel .backward:before {
			left: -3em;
		}

		.carousel .backward:after {
			left: -0.25em;
			-moz-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			-ms-transform: scaleX(-1);
			transform: scaleX(-1);
		}

	.carousel .reel {
		white-space: nowrap;
		position: relative;
		-webkit-overflow-scrolling: touch;
		padding: 0 2em 0 2em;
	}

	.carousel article {
		display: inline-block;
		width: 18em;
		background: #fff;
		text-align: center;
		padding: 0 1em 3em 1em;
		margin: 0 2em 0 0;
		white-space: normal;
		opacity: 1.0;
		-moz-transition: opacity 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out;
	}

		.carousel article.loading {
			opacity: 0;
		}

		.carousel article .image {
			position: relative;
			left: -1em;
			top: 0;
			width: auto;
			margin-right: -2em;
			margin-bottom: 0em;
		}

		.carousel article p {
			text-align: center;
		}



/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #f5f7fa;
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 300;
		color: #52575c;
		line-height: 1.75em;
		font-size: 14pt;
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

	input, textarea, select {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 300;
		color: #52575c;
		line-height: 1.75em;
		font-size: 15pt;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 300;
		color: #52575c;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
	}

	h2 {
		font-size: 2em;
		line-height: 1.25em;
	}

	h3 {
		font-size: 1.5em;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.0em;
		line-height: 1.75em;
	}

	a {
		-moz-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		-webkit-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		-ms-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		color: inherit;
		text-decoration: none;
		border-bottom: dotted 1px #62676c;
	}

		a:hover {
			color: var(--hoverBlue);
			border-bottom-color: rgba(255, 255, 255, 0);
		}

	strong, b {
		font-weight: 400;
		color: #42474c;
	}

	em, i {
		font-style: italic;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	hr {
		border: 0;
		border-top: solid 1px #c2c7cc;
	}

	blockquote {
		border-left: solid 0.5em #c2c7cc;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	p, ul, ol, dl, table {
		margin-bottom: 1.5em;
	}

	br.clear {
		clear: both;
	}

	.features {
		margin-bottom: 1.5em;
	}

	.feature {
		text-align: center;
	}

/* Sections/Article */

	section, article {
		margin-bottom: 3em;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}

	.row > section, .row > article {
		margin-bottom: 0;
	}

	header {
		margin-bottom: 1.5em;
	}

		header > p {
			display: block;
			color: var(--hoverBlue);
			padding: 0.75em 0 1em 0;
			font-size: 1.5em;
			line-height: 1.5em;
		}

		header.major {
			text-align: center;
		}

/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 1200px;
	}

		@media screen and (max-width: 1280px) {

			.container {
				width: 960px;
			}

		}

		@media screen and (max-width: 1080px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 820px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 480px) {

			.container {
				width: 100%;
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

			.row.gtr-0 > * {
				padding: 0px 0 0 0px;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0px;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0px;
				}

		.row.gtr-25 {
			margin-top: -12.5px;
			margin-left: -12.5px;
		}

			.row.gtr-25 > * {
				padding: 12.5px 0 0 12.5px;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -12.5px;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 12.5px;
				}

		.row.gtr-50 {
			margin-top: -25px;
			margin-left: -25px;
		}

			.row.gtr-50 > * {
				padding: 25px 0 0 25px;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -25px;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 25px;
				}

		.row {
			margin-top: -50px;
			margin-left: -50px;
		}

			.row > * {
				padding: 50px 0 0 50px;
			}

			.row.gtr-uniform {
				margin-top: -50px;
			}

				.row.gtr-uniform > * {
					padding-top: 50px;
				}

		.row.gtr-150 {
			margin-top: -75px;
			margin-left: -75px;
		}

			.row.gtr-150 > * {
				padding: 75px 0 0 75px;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -75px;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 75px;
				}

		.row.gtr-200 {
			margin-top: -100px;
			margin-left: -100px;
		}

			.row.gtr-200 > * {
				padding: 100px 0 0 100px;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -100px;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 100px;
				}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-normal {
					order: -1;
				}

				.row > .col-1-normal {
					width: 8.33333%;
				}

				.row > .off-1-normal {
					margin-left: 8.33333%;
				}

				.row > .col-2-normal {
					width: 16.66667%;
				}

				.row > .off-2-normal {
					margin-left: 16.66667%;
				}

				.row > .col-3-normal {
					width: 25%;
				}

				.row > .off-3-normal {
					margin-left: 25%;
				}

				.row > .col-4-normal {
					width: 33.33333%;
				}

				.row > .off-4-normal {
					margin-left: 33.33333%;
				}

				.row > .col-5-normal {
					width: 41.66667%;
				}

				.row > .off-5-normal {
					margin-left: 41.66667%;
				}

				.row > .col-6-normal {
					width: 50%;
				}

				.row > .off-6-normal {
					margin-left: 50%;
				}

				.row > .col-7-normal {
					width: 58.33333%;
				}

				.row > .off-7-normal {
					margin-left: 58.33333%;
				}

				.row > .col-8-normal {
					width: 66.66667%;
				}

				.row > .off-8-normal {
					margin-left: 66.66667%;
				}

				.row > .col-9-normal {
					width: 75%;
				}

				.row > .off-9-normal {
					margin-left: 75%;
				}

				.row > .col-10-normal {
					width: 83.33333%;
				}

				.row > .off-10-normal {
					margin-left: 83.33333%;
				}

				.row > .col-11-normal {
					width: 91.66667%;
				}

				.row > .off-11-normal {
					margin-left: 91.66667%;
				}

				.row > .col-12-normal {
					width: 100%;
				}

				.row > .off-12-normal {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-25 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 10px;
						}

				.row.gtr-50 {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row.gtr-50 > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 20px;
						}

				.row {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-uniform > * {
							padding-top: 40px;
						}

				.row.gtr-150 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-150 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 60px;
						}

				.row.gtr-200 {
					margin-top: -80px;
					margin-left: -80px;
				}

					.row.gtr-200 > * {
						padding: 80px 0 0 80px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -80px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 80px;
						}

		}

		@media screen and (max-width: 1080px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrow {
					order: -1;
				}

				.row > .col-1-narrow {
					width: 8.33333%;
				}

				.row > .off-1-narrow {
					margin-left: 8.33333%;
				}

				.row > .col-2-narrow {
					width: 16.66667%;
				}

				.row > .off-2-narrow {
					margin-left: 16.66667%;
				}

				.row > .col-3-narrow {
					width: 25%;
				}

				.row > .off-3-narrow {
					margin-left: 25%;
				}

				.row > .col-4-narrow {
					width: 33.33333%;
				}

				.row > .off-4-narrow {
					margin-left: 33.33333%;
				}

				.row > .col-5-narrow {
					width: 41.66667%;
				}

				.row > .off-5-narrow {
					margin-left: 41.66667%;
				}

				.row > .col-6-narrow {
					width: 50%;
				}

				.row > .off-6-narrow {
					margin-left: 50%;
				}

				.row > .col-7-narrow {
					width: 58.33333%;
				}

				.row > .off-7-narrow {
					margin-left: 58.33333%;
				}

				.row > .col-8-narrow {
					width: 66.66667%;
				}

				.row > .off-8-narrow {
					margin-left: 66.66667%;
				}

				.row > .col-9-narrow {
					width: 75%;
				}

				.row > .off-9-narrow {
					margin-left: 75%;
				}

				.row > .col-10-narrow {
					width: 83.33333%;
				}

				.row > .off-10-narrow {
					margin-left: 83.33333%;
				}

				.row > .col-11-narrow {
					width: 91.66667%;
				}

				.row > .off-11-narrow {
					margin-left: 91.66667%;
				}

				.row > .col-12-narrow {
					width: 100%;
				}

				.row > .off-12-narrow {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-25 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 10px;
						}

				.row.gtr-50 {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row.gtr-50 > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 20px;
						}

				.row {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-uniform > * {
							padding-top: 40px;
						}

				.row.gtr-150 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-150 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 60px;
						}

				.row.gtr-200 {
					margin-top: -80px;
					margin-left: -80px;
				}

					.row.gtr-200 > * {
						padding: 80px 0 0 80px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -80px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 80px;
						}

		}

		@media screen and (max-width: 820px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrower {
					order: -1;
				}

				.row > .col-1-narrower {
					width: 8.33333%;
				}

				.row > .off-1-narrower {
					margin-left: 8.33333%;
				}

				.row > .col-2-narrower {
					width: 16.66667%;
				}

				.row > .off-2-narrower {
					margin-left: 16.66667%;
				}

				.row > .col-3-narrower {
					width: 25%;
				}

				.row > .off-3-narrower {
					margin-left: 25%;
				}

				.row > .col-4-narrower {
					width: 33.33333%;
				}

				.row > .off-4-narrower {
					margin-left: 33.33333%;
				}

				.row > .col-5-narrower {
					width: 41.66667%;
				}

				.row > .off-5-narrower {
					margin-left: 41.66667%;
				}

				.row > .col-6-narrower {
					width: 50%;
				}

				.row > .off-6-narrower {
					margin-left: 50%;
				}

				.row > .col-7-narrower {
					width: 58.33333%;
				}

				.row > .off-7-narrower {
					margin-left: 58.33333%;
				}

				.row > .col-8-narrower {
					width: 66.66667%;
				}

				.row > .off-8-narrower {
					margin-left: 66.66667%;
				}

				.row > .col-9-narrower {
					width: 75%;
				}

				.row > .off-9-narrower {
					margin-left: 75%;
				}

				.row > .col-10-narrower {
					width: 83.33333%;
				}

				.row > .off-10-narrower {
					margin-left: 83.33333%;
				}

				.row > .col-11-narrower {
					width: 91.66667%;
				}

				.row > .off-11-narrower {
					margin-left: 91.66667%;
				}

				.row > .col-12-narrower {
					width: 100%;
				}

				.row > .off-12-narrower {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -7.5px;
					margin-left: -7.5px;
				}

					.row.gtr-25 > * {
						padding: 7.5px 0 0 7.5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -7.5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 7.5px;
						}

				.row.gtr-50 {
					margin-top: -15px;
					margin-left: -15px;
				}

					.row.gtr-50 > * {
						padding: 15px 0 0 15px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -15px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 15px;
						}

				.row {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-150 {
					margin-top: -45px;
					margin-left: -45px;
				}

					.row.gtr-150 > * {
						padding: 45px 0 0 45px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -45px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 45px;
						}

				.row.gtr-200 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-200 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 60px;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-mobile {
					order: -1;
				}

				.row > .col-1-mobile {
					width: 8.33333%;
				}

				.row > .off-1-mobile {
					margin-left: 8.33333%;
				}

				.row > .col-2-mobile {
					width: 16.66667%;
				}

				.row > .off-2-mobile {
					margin-left: 16.66667%;
				}

				.row > .col-3-mobile {
					width: 25%;
				}

				.row > .off-3-mobile {
					margin-left: 25%;
				}

				.row > .col-4-mobile {
					width: 33.33333%;
				}

				.row > .off-4-mobile {
					margin-left: 33.33333%;
				}

				.row > .col-5-mobile {
					width: 41.66667%;
				}

				.row > .off-5-mobile {
					margin-left: 41.66667%;
				}

				.row > .col-6-mobile {
					width: 50%;
				}

				.row > .off-6-mobile {
					margin-left: 50%;
				}

				.row > .col-7-mobile {
					width: 58.33333%;
				}

				.row > .off-7-mobile {
					margin-left: 58.33333%;
				}

				.row > .col-8-mobile {
					width: 66.66667%;
				}

				.row > .off-8-mobile {
					margin-left: 66.66667%;
				}

				.row > .col-9-mobile {
					width: 75%;
				}

				.row > .off-9-mobile {
					margin-left: 75%;
				}

				.row > .col-10-mobile {
					width: 83.33333%;
				}

				.row > .off-10-mobile {
					margin-left: 83.33333%;
				}

				.row > .col-11-mobile {
					width: 91.66667%;
				}

				.row > .off-11-mobile {
					margin-left: 91.66667%;
				}

				.row > .col-12-mobile {
					width: 100%;
				}

				.row > .off-12-mobile {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}

		}

		@media screen and (max-width: 480px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-mobilep {
					order: -1;
				}

				.row > .col-1-mobilep {
					width: 8.33333%;
				}

				.row > .off-1-mobilep {
					margin-left: 8.33333%;
				}

				.row > .col-2-mobilep {
					width: 16.66667%;
				}

				.row > .off-2-mobilep {
					margin-left: 16.66667%;
				}

				.row > .col-3-mobilep {
					width: 25%;
				}

				.row > .off-3-mobilep {
					margin-left: 25%;
				}

				.row > .col-4-mobilep {
					width: 33.33333%;
				}

				.row > .off-4-mobilep {
					margin-left: 33.33333%;
				}

				.row > .col-5-mobilep {
					width: 41.66667%;
				}

				.row > .off-5-mobilep {
					margin-left: 41.66667%;
				}

				.row > .col-6-mobilep {
					width: 50%;
				}

				.row > .off-6-mobilep {
					margin-left: 50%;
				}

				.row > .col-7-mobilep {
					width: 58.33333%;
				}

				.row > .off-7-mobilep {
					margin-left: 58.33333%;
				}

				.row > .col-8-mobilep {
					width: 66.66667%;
				}

				.row > .off-8-mobilep {
					margin-left: 66.66667%;
				}

				.row > .col-9-mobilep {
					width: 75%;
				}

				.row > .off-9-mobilep {
					margin-left: 75%;
				}

				.row > .col-10-mobilep {
					width: 83.33333%;
				}

				.row > .off-10-mobilep {
					margin-left: 83.33333%;
				}

				.row > .col-11-mobilep {
					width: 91.66667%;
				}

				.row > .off-11-mobilep {
					margin-left: 91.66667%;
				}

				.row > .col-12-mobilep {
					width: 100%;
				}

				.row > .off-12-mobilep {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}

		}

/* Image */

	.image {
		-moz-transition: opacity 0.25s ease-in-out;
		-webkit-transition: opacity 0.25s ease-in-out;
		-ms-transition: opacity 0.25s ease-in-out;
		transition: opacity 0.25s ease-in-out;
		display: inline-block;
		outline: 0;
		border: 0;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 3em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 3em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

	a.image:hover {
		opacity: 0.85;
	}

/* List */

	ul {
		list-style: disc;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

	ol {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

/* Actions */

	ul.actions {
		padding-top: 1em;
		list-style: none;
		padding-left: 0;
	}

		ul.actions.major {
			text-align: center;
		}

		ul.actions li {
			display: inline-block;
			margin-left: 1em;
			padding-left: 0;
		}

			ul.actions li:first-child {
				margin-left: 0;
			}

/* Divided */

	ul.divided {
		list-style: none;
		padding-left: 0;
	}

		ul.divided li {
			border-top: solid 1px #d2d7dc;
			padding-top: 2em;
			margin-top: 2em;
			padding-left: 0;
		}

			ul.divided li:first-child {
				padding-top: 0;
				margin-top: 0;
				border-top: 0;
			}

/* Icons */

	ul.icons {
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			padding-left: 0;
		}

			ul.icons li:before {
				display: inline-block;
				color: #fff;
				background: #61666b;
				width: 2.5em;
				height: 2.5em;
				border-radius: 0.35em;
				text-align: center;
				line-height: 2.5em;
				margin-right: 0.75em;
			}

/* Menu */

	ul.menu {
		list-style: none;
		padding-left: 0;
	}

		ul.menu li {
			display: inline-block;
			border-left: solid 1px #d2d7dc;
			padding-left: 1em;
			margin-left: 1em;
		}

			ul.menu li:first-child {
				border-left: 0;
				padding-left: 0;
				margin-left: 0;
			}

/* Form */

	form .actions {
		padding-top: 0;
	}

	form label {
		display: block;
		font-weight: 300;
		color: #52575c;
		margin: 0 0 0.5em 0;
	}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select,
	form textarea {
		-moz-transition: background-color 0.25s ease-in-out;
		-webkit-transition: background-color 0.25s ease-in-out;
		-ms-transition: background-color 0.25s ease-in-out;
		transition: background-color 0.25s ease-in-out;
		-webkit-appearance: none;
		display: block;
		background: #f9fbfe;
		border: solid 1px #d2d7dc;
		border-radius: 0.35em;
		width: 100%;
		line-height: 1.5em;
		padding: 0.75em;
	}

		form input[type="text"]:focus,
		form input[type="email"]:focus,
		form input[type="password"]:focus,
		form select:focus,
		form textarea:focus {
			background: #fff;
		}

	form textarea {
		min-height: 7em;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Table */

/*	table {
		width: 100%;
	}

		table.default {
			width: 100%;
		}

			table.default tbody tr {
				border-top: solid 1px #D2D7DC;
			}

				table.default tbody tr:nth-child(2n+1) {
					background: #f9fbfe;
				}

			table.default td {
				padding: 0.5em 1em 0.5em 1em;
				color: #000;
			}

			table.default th {
				text-align: left;
				font-weight: 400;
				padding: 0.5em 1em 0.5em 1em;
			}

			table.default tfoot {
				border-top: solid 1px #D2D7DC;
			}*/

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
		position: relative;
	}

	table {
		margin: 0 0 2rem 0;
		width: 100%;
	}

		table tbody tr {
			border: solid 1px;
			border-left: 0;
			border-right: 0;
		}

		table td {
			padding: 0.75rem 0.75rem;
		}

		table th {
			font-size: 0.9rem;
			font-weight: 400;
			padding: 0 0.75rem 0.75rem 0.75rem;
			text-align: left;
		}

		table thead {
			border-bottom: solid 2px;
		}

		table tfoot {
			border-top: solid 2px;
		}

		table.alt {
			border-collapse: separate;
		}

			table.alt tbody tr td {
				border: solid 1px;
				border-left-width: 0;
				border-top-width: 0;
			}

				table.alt tbody tr td:first-child {
					border-left-width: 1px;
				}

			table.alt tbody tr:first-child td {
				border-top-width: 1px;
			}

			table.alt thead {
				border-bottom: 0;
			}

			table.alt tfoot {
				border-top: 0;
			}

	table tbody tr {
		border-color: rgba(0, 0, 0, 0.125);
	}

		table tbody tr:nth-child(2n + 1) {
			background-color: rgba(0, 0, 0, 0.05);
		}

	table th {
		color: #000000;
	}

	table thead {
		border-bottom-color: rgba(0, 0, 0, 0.125);
	}

	table tfoot {
		border-top-color: rgba(0, 0, 0, 0.125);
	}

	table.alt tbody tr td {
		border-color: rgba(0, 0, 0, 0.125);
	}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		-moz-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		-webkit-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		-ms-transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
		display: inline-block;
		color: inherit;
		background: none;
		text-decoration: none;
		border: solid 1px #c2c7cc;
		border-radius: 0.35em;
		padding: 0.8em 2em 0.8em 2em;
		cursor: pointer;
		outline: 0;
	}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			color: var(--hoverBlue);
			border-color: var(--hoverBlueOutline);
		}

/* Icons */

	.icon {
		text-decoration: none;
		text-decoration: none;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon:before {
			line-height: inherit;
		}

		.icon > .label {
			display: none;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

/* Wrappers */

	.wrapper {
		position: relative;
		background: #fff;
		overflow-x: hidden;
		padding: 4em 0 0em 0;
	}

	.image-wrapper {
		position: relative;
	}

		.image-wrapper .image {
			position: relative;
			z-index: 2;
		}

		.image-wrapper.first:before {
			content: '';
			display: block;
			width: 2000%;
			height: 100%;
			position: absolute;
			left: -1000%;
			top: 0;
			z-index: 1;
			box-shadow: inset 0px 0px 0px 1px #e8e8e8;
			background: #f6f8fb url("images/image-wrapper.svg");
		}

	#header-wrapper {
		position: relative;
		padding: 4em 0 2em 0;
		background-image: url("images/header.svg");
		background-image: url("images/overlay.png"), url("images/header.svg"), -moz-linear-gradient(75deg, var(--darkBlueBackground) 15%, var(--midBlueBackground) 55%);
		background-image: url("images/overlay.png"), url("images/header.svg"), -webkit-linear-gradient(75deg, var(--darkBlueBackground) 15%, var(--midBlueBackground) 55%);
		background-image: url("images/overlay.png"), url("images/header.svg"), -ms-linear-gradient(75deg, var(--darkBlueBackground) 15%, var(--midBlueBackground) 55%);
		background-image: url("images/overlay.png"), url("images/header.svg"), linear-gradient(75deg, var(--darkBlueBackground) 15%, var(--midBlueBackground) 55%);
		background-color: var(--lightBlueBackground);
		background-size: 100% 620%;
		background-size: 128px 128px, 100% 620%, auto;
		background-repeat: repeat, no-repeat, no-repeat;
	}

	.homepage #header-wrapper {
		padding: 10em 0 6em 0;
		background-size: 128px 128px, 100% 100%, auto;
	}

	#footer-wrapper {
		-webkit-transform: translate3d(0, 0, 0);
		position: relative;
		border-top: solid 1px #c2c7cc;
		padding: 4em 0 4em 0;
		background-image: url("images/overlay.png"), url("images/footer.svg");
		background-size: 128px 128px,				100% 100%;
	}

/* Header */

	#logo {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 50%;
		width: 15em;
		margin-left: -7.5em;
	}

		#logo a {
			display: block;
			width: 100%;
			height: 4em;
			line-height: 4em;
			background: rgba(255, 255, 255, 0.15);
			border: solid 1px;
			border-color: #fff;
			border-color: rgba(255, 255, 255, 0.75);
			border-top: 0;
			border-radius: 0 0 0.35em 0.35em;
			color: #fff;
			display: inline-block;
			font-weight: 600;
			letter-spacing: 3px;
			text-align: center;
			text-transform: uppercase;
		}

	#nav {
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4em;
		line-height: 4em;
	}

		#nav > ul {
			margin: 0;
		}

			#nav > ul > li {
				display: inline-block;
				margin-left: 1em;
			}

				#nav > ul > li > ul {
					display: none;
				}

				#nav > ul > li > span, #nav > ul > li > a {
					color: #fff;
					text-decoration: none;
					outline: 0;
					border: 0;
					border: solid 1px rgba(255, 255, 255, 0);
					border-radius: 0.35em;
					padding: 0.3em 1em 0.3em 1em;
					-moz-transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
					-webkit-transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
					-o-transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
					-ms-transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
					transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
				}

				#nav > ul > li.active > span, #nav > ul > li.active > a {
					border-color: rgba(255, 255, 255, 0.75);
					background-color: rgba(255, 255, 255, 0.15);
					color: #fff;
				}

				#nav > ul > li:first-child {
					margin-left: 0;
				}

				#nav > ul > li.break {
					margin-left: 20em;
				}

	.dropotron {
		text-align: left;
		line-height: 2em;
		border-radius: 0.35em;
		min-width: 12em;
		background: #fff;
		line-height: 1em;
		padding: 0.85em 0 0.85em 0;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		margin-top: -0.8em;
		list-style: none;
	}

		.dropotron a, .dropotron span {
			display: block;
			padding: 0.65em 1em 0.65em 1em;
			border-top: solid 1px #e8e8e8;
			border-bottom: 0;
		}

		.dropotron li {
			padding-left: 0;
		}

			.dropotron li:first-child > a, .dropotron li:first-child > span {
				border-top: 0;
			}

			.dropotron li.active > a, .dropotron li.active > span {
				color: #e8e8e8;
			}

		.dropotron.level-0 {
			font-size: 0.9em;
			margin-top: 0;
		}

			.dropotron.level-0:before {
				content: '';
				position: absolute;
				top: -1em;
				width: 1em;
				height: 1em;
				border-bottom: solid 0.5em #fff;
				border-left: solid 0.5em rgba(255, 255, 255, 0);
				border-right: solid 0.5em rgba(255, 255, 255, 0);
			}

			.dropotron.level-0.center:before {
				left: 50%;
				margin-left: -0.5em;
			}

			.dropotron.level-0.left:before {
				left: 1em;
			}

			.dropotron.level-0.right:before {
				right: 1em;
			}

/* Hero */

	#hero {
		position: relative;
		color: #fff;
		text-align: center;
		z-index: 1;
	}

		#hero h2 {
			color: #fff;
			font-size: 3em;
		}

		#hero strong {
			color: inherit;
		}

		#hero header > span {
			color: #fff;
		}

		#hero p {
			font-size: 1.5em;
			line-height: 1.5em;
		}

		#hero .button {
			background: #fff;
			border-color: rgba(255, 255, 255, 0);
			color: var(--darkBlueBackground);
			font-size: 1.25em;
		}

			#hero .button:hover {
				border-color: rgba(255, 255, 255, 0.75);
				background-color: rgba(255, 255, 255, 0.15);
				color: #fff;
			}

		#hero a {
			color: #fff;
			border-color: #fff;
			border-color: rgba(255, 255, 255, 0.5);
		}

			#hero a:hover {
				border-color: rgba(255, 255, 255, 0);
			}

/* Promo */

	#promo {
		background: rgba(255, 255, 255, 0.05);
		border: solid 1px rgba(255, 255, 255, 0.25);
		border-radius: 0.5em;
		padding: 1.75em 2.5em 1.75em 2.5em;
		display: inline-block;
		position: relative;
		z-index: 1;
	}

		#promo h2 {
			color: #fff;
			display: inline-block;
			margin-right: 1em;
			font-size: 1.75em;
		}

		#promo .button {
			background: #fff;
			border-color: rgba(255, 255, 255, 0);
			color: #27636B;
			font-size: 1.25em;
		}

			#promo .button:hover {
				border-color: rgba(255, 255, 255, 0.75);
				background-color: rgba(255, 255, 255, 0.15);
				color: #fff;
			}

/* Main */

	#main {
		padding-bottom: 4em;
	}

/* Sidebar */

	#sidebar section {
		border-top: solid 1px #d2d7dc;
		margin-top: 3em;
		padding-top: 3em;
	}

		#sidebar section:first-child {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}

/* Footer */

	#footer {
		position: relative;
		z-index: 1;
	}

		#footer header.major {
			margin-bottom: 3em;
		}

/* Copyright */

	#copyright {
		position: relative;
		z-index: 1;
		text-align: center;
		border-top: solid 1px #d2d7dc;
		color: #a2a7ac;
		padding-top: 4em;
		margin-top: 6em;
	}

/* Normal */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, textarea, select {
				line-height: 1.5em;
				font-size: 13pt;
			}

			span.extra {
				display: none;
			}

		/* Wrappers */

			.wrapper {
				padding: 5em 0 5em 0;
			}

			#header-wrapper {
				padding: 2em 0 2em 0;
			}

			.homepage #header-wrapper {
				padding: 8em 0 4em 0;
			}

			#promo-wrapper {
				padding: 5em 0 5em 0;
			}

			#footer-wrapper {
				padding: 5em 0 5em 0;
			}

	}

/* Narrow */

	@media screen and (max-width: 1080px) {

		/* Sections/Article */

			header br {
				display: none;
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				padding-left: 1.25em;
				padding-right: 1.25em;
			}

		/* Wrappers */

			.wrapper {
				padding: 4em 2em 4em 2em;
			}

			#header-wrapper {
				padding: 5em 0 1em 0;
			}

			.homepage #header-wrapper {
				padding: 5em 0 4em 0;
			}

			#promo-wrapper {
				padding: 2em;
			}

			#footer-wrapper {
				padding: 4em 2em 4em 2em;
			}

		/* Header */

			#nav {
				position: relative;
				margin: 0.5em 0 0 0;
				line-height: 2.25em;
				height: 2.25em;
			}

				#nav > ul > li {
					margin-left: 0;
				}

					#nav > ul > li.break {
						margin-left: 0;
					}

			.dropotron.level-0 {
				margin-top: 1.1em;
			}

		/* Hero */

			#hero {
				padding: 3.5em 4em 0 4em;
			}

				#hero h2 {
					font-size: 2.75em;
				}

				#hero p br {
					display: none;
				}

		/* Promo */

			#promo {
				padding: 1.75em 2.5em 1.75em 2.5em;
				display: inline-block;
			}

				#promo .button {
					font-size: 1.25em;
				}

				#promo h2 {
					display: block;
					margin: 0 0 1em 0;
					font-size: 1.5em;
				}

		/* Copyright */

			#copyright {
				padding-top: 2em;
				margin-top: 3em;
			}

	}

/* Narrower */

	@media screen and (max-width: 820px) {

		/* Basic */

			body, input, textarea, select {
				line-height: 1.5em;
				font-size: 14pt;
			}

			span.extra {
				display: inline;
			}

			.features {
				margin-bottom: 0;
			}

		/* Sections/Article */

			section, article {
				margin-bottom: 3em;
			}

			.row > section, .row > article {
				margin-bottom: 3em;
			}

			section:last-child,
			article:last-child {
				margin-bottom: 0;
			}

			.row > section:last-child,
			.row > article:last-child {
				margin-bottom: 0;
			}

		/* Menu */

			ul.menu {
				text-align: center;
			}

				ul.menu li {
					display: block;
					border: 0;
					padding: 0;
					margin: 0;
					line-height: 2em;
				}

					ul.menu li:first-child {
						border-top: 0;
					}

		/* Actions */

			ul.actions.major {
				padding-top: 2em;
			}

		/* Header */

			#nav {
				font-size: 0.9em;
			}

				#nav > ul > li > span, #nav > ul > li > a {
					padding: 0.25em 0.5em 0.25em 0.5em;
				}

		/* Hero */

			#hero h2 {
				font-size: 2.5em;
			}

		/* Main */

			#main {
				padding-bottom: 0;
			}

		/* Content */

			#content {
				margin-bottom: 3em;
			}

		/* Sidebar */

			#sidebar {
				margin-bottom: 3em;
			}

				#sidebar section {
					border-top: 0;
					margin-top: 0;
					padding-top: 1em;
				}

		/* Footer */

			#footer header.major {
				margin-bottom: 1.5em;
			}

	}

/* Mobile */

	#navPanel, #navButton {
		display: none;
	}

	@media screen and (max-width: 736px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

			body, input, textarea, select {
				font-size: 13pt;
				letter-spacing: 0;
			}

			h2 {
				font-size: 1.5em;
				line-height: 1.25em;
			}

		/* Sections/Article */

			header {
				margin-bottom: 1em;
			}

				header > p {
					font-size: 1em;
				}

		/* Divided */

			ul.divided li {
				padding-top: 0.75em;
				margin-top: 0.75em;
			}

		/* Wrappers */

			.wrapper {
				padding: 3em 20px 3em 20px;
			}

			#header-wrapper {
				padding: 3em 20px 20px 20px;
				text-align: center;
			}

			.homepage #header-wrapper {
				padding: 3em 20px 3em 20px;
			}

			#promo-wrapper {
				padding: 20px;
			}

			#footer-wrapper {
				padding: 3em 20px 3em 20px;
			}

		/* Header */

			#logo {
				position: relative;
			}

				#logo a {
					border: solid 1px rgba(255, 255, 255, 0.75);
					border-radius: 0.35em;
					height: 3em;
					line-height: 3em;
				}

		/* Nav */

			#nav {
				display: none;
			}

		/* Footer */

			#footer ul.divided {
				margin: 0;
			}

				#footer ul.divided li:first-child {
					border-top: solid 1px #d2d7dc;
					padding-top: 0.75em;
					margin-top: 0.75em;
				}

				#footer ul.divided:first-child li:first-child {
					border-top: 0;
					padding-top: 0;
					margin-top: 0;
				}

		/* Hero */

			#hero {
				padding: 2.5em 2em 0 2em;
			}

				#hero header {
					margin: 0 0 1.25em 0;
				}

					#hero header h2 {
						font-size: 1.5em;
					}

				#hero p {
					font-size: 1em;
				}

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#navButton {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 10001;
			}

				#navButton .toggle {
					text-decoration: none;
					border: 0;
					height: 100%;
					left: 0;
					outline: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

					#navButton .toggle:before {
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						text-transform: none !important;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
					}

					#navButton .toggle:before {
						background: rgba(128, 132, 136, 0.35);
						border-radius: 0 0 0.35em 0.35em;
						border-top: 0;
						color: #fff;
						content: '\f0c9';
						display: block;
						font-size: 18px;
						height: 30px;
						left: 50%;
						line-height: 30px;
						margin-left: -40px;
						position: absolute;
						text-align: center;
						text-decoration: none;
						width: 80px;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateY(-50vh);
				-webkit-transform: translateY(-50vh);
				-ms-transform: translateY(-50vh);
				transform: translateY(-50vh);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				background-image: url("images/overlay.png"), url("images/navPanel.svg"), -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
				background-image: url("images/overlay.png"), url("images/navPanel.svg"), -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
				background-image: url("images/overlay.png"), url("images/navPanel.svg"), -ms-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
				background-image: url("images/overlay.png"), url("images/navPanel.svg"), linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
				display: block;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 100%;
				height: 50vh;
				z-index: 10002;
				box-shadow: inset 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
				font-size: 1em;
				background-color: #45474a;
				background-size: 128px 128px, 100% 100%, 100% 100%;
			}

				#navPanel .link {
					position: relative;
					z-index: 1;
					display: block;
					text-decoration: none;
					padding: 0.75em;
					color: #ddd;
					border: 0;
					border-top: dotted 1px rgba(255, 255, 255, 0.1);
				}

					#navPanel .link.depth-0 {
						color: #fff;
						font-weight: 400;
					}

				#navPanel .indent-1 {
					display: inline-block;
					width: 1em;
				}

				#navPanel .indent-2 {
					display: inline-block;
					width: 2em;
				}

				#navPanel .indent-3 {
					display: inline-block;
					width: 3em;
				}

				#navPanel .indent-4 {
					display: inline-block;
					width: 4em;
				}

				#navPanel .indent-5 {
					display: inline-block;
					width: 5em;
				}

				#navPanel .depth-0 {
					color: #fff;
				}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateY(50vh);
				-webkit-transform: translateY(50vh);
				-ms-transform: translateY(50vh);
				transform: translateY(50vh);
			}

			body.navPanel-visible #navButton {
				-moz-transform: translateY(50vh);
				-webkit-transform: translateY(50vh);
				-ms-transform: translateY(50vh);
				transform: translateY(50vh);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
			}

	}

/* Mobile (Portrait) */

	@media screen and (max-width: 480px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 12pt;
				letter-spacing: 0;
			}

			.feature {
				text-align: left;
			}

		/* Sections/Article */

			section, article {
				margin-bottom: 2em;
			}

			.row > section,
			.row > article {
				margin-bottom: 2em;
			}

			header.major {
				text-align: left;
			}

		/* Image */

			.image {
				display: block;
			}

				.image.full, .image.left {
					float: none;
					display: block;
					width: 100%;
					margin: 0 0 1.5em 0;
				}

			.image-centered {
				float: none;
				display: block;
				width: 100%;
				margin: 0 0 1.5em 0;
			}

		/* Actions */

			ul.actions li {
				display: block;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

			ul.actions.major {
				text-align: left !important;
			}

		/* Form */

			form textarea {
				min-height: 10em;
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				display: block;
				width: 100%;
				text-align: center;
			}

		/* Header */

			#logo {
				position: relative;
				left: 0;
				top: 0;
				margin: 0;
				width: 100%;
			}

		/* Hero */

			#hero {
				padding: 2.5em 0 0 0;
			}

		/* Promo */

			#promo {
				padding: 20px;
			}

		/* Content */

			#content {
				margin-bottom: 2em;
			}

		/* Sidebar */

			#sidebar {
				margin-bottom: 2em;
			}

		/* Footer */

			#footer header.major {
				margin-bottom: 1em;
			}

	}